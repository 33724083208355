@use "../styles/theme.scss";

:local(.sign-in-button) {
  display: none !important;
  @media (min-width: theme.$breakpoint-lg) {
    display: flex !important;
  }
}

:local(.mobile-sign-in) {
  display: flex !important;
  @media (min-width: theme.$breakpoint-lg) {
    display: none !important;
  }
}
