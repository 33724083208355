@use "../styles/theme.scss";

:local(.guide-layout) {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    border-bottom: 2px solid black;
    padding: theme.$spacing-xl 0px;
    @media (min-width: theme.$breakpoint-md) {
      width: theme.$breakpoint-md;
    }
  }

  img:last-child {
    border-bottom: none;
  }
}
